import { ACTION_TYPES } from '../actions';

const INIT_STATE = {};

function global(state = INIT_STATE, action) {
  let result = { ...state };
  const { type } = action;
  switch (type) {
    case ACTION_TYPES.GLOBAL_CLEAR:
      result = INIT_STATE
      break;
    default:
      result = state;
      break;
  }
  return result;
}

export default global;
