import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout as AntdLayout } from 'antd';

require('./style.scss');

const Layout = () => {
  return (
    <AntdLayout className="layout">
      <Suspense fallback={<div>loading...</div>}>
        <Outlet />
      </Suspense>
    </AntdLayout>
  )
}

export default Layout;