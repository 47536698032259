import { combineReducers } from 'redux';

import errors from './errors';
import global from './global';

const rootReducer = combineReducers({
  errors,
  global,
});

export default rootReducer;
