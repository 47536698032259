const GLOBAL_CLEAR = 'GLOBAL_CLEAR';
const UPDATE_GLOBAL_EDIT_PERMISSION = 'UPDATE_GLOBAL_EDIT_PERMISSION';

/**
 * 更新企业编辑权限
 */
async function updateGlobalEditPermission(editPermission) {
  return {
    type: UPDATE_GLOBAL_EDIT_PERMISSION,
    payload: editPermission
  }
}

const ACTION_TYPES = {
  GLOBAL_CLEAR,
  UPDATE_GLOBAL_EDIT_PERMISSION,
};

const ACTION = {
  updateGlobalEditPermission,
};

const Global = {
  ACTION_TYPES,
  ACTION
}

export default Global;