import GlobalActions from './Global';

const ACTION = {
  ...GlobalActions.ACTION
};

const ACTION_TYPES = {
  ...GlobalActions.ACTION_TYPES
};

const Global = {
  ACTION,
  ACTION_TYPES
}

export default Global;