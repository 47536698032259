import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from '../reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const root = rootReducer;
// 调用日志打印方法
const logger = createLogger({
  diff: false,
});
// 生产环境不调用日志?
const middleware = process.env.NODE_ENV === 'production' ? [thunk] : [thunk, logger];

export default createStore(
  root,
  composeEnhancers(applyMiddleware(...middleware)),
);
