export const ERRORS = {
  ERROR_401: 'ERROR_401',
  ERROR_500: 'ERROR_500',
  ERROR_BIZ: 'ERROR_BIZ',
};

export const GENDER = {
  '1': '男',
  '2': '女',
}

export const ID_CARD_TYPE = {
  '1': '身份证',
  '2': '军官证',
  '3': '护照',
  '4': '台湾居民通行证',
  '5': '港澳居民通行证',
  '9': '其他',
}

// 属性字典类型
export const ATTR_DIC_TYPE = {
  '1178': '文本框',
  '1179': '手机号',
  '1180': '日期',
  '1181': '日期+时间',
  '1182': '数字框',
  '1183': '多选下拉框',
  '1184': '多行文本框',
  '1185': '图片',
  '1186': '单选下拉框',
  '1187': '单选框'
}

// 产品类型
export const SPU_TYPE = {
  'GTY': '跟团游',
  'ZYX': '自由行',
  'JD': '酒店',
  'PQ': '票券',
  'SW': '实物',
};

// 出行人分类
export const PERSON_TYPE = {
  1: '成人',
  2: '儿童',
  3: '老人',
  4: '婴儿'
}

// 订单类型
export const ORDER_TYPE = {
  '10': '跟团游',
  '20': '自由行',
  '40': '酒店',
  '30': '票券',
  '50': '实物',
};

// 订单状态
export const ORDER_STATUS = {
  '10': '待付款',
  '20': '已付款',
  '30': '待发货',
  '31': '待出行',
  '40': '待收货',
  '60': '交易完成',
  '80': '售后中',
  '90': '已取消',
  '100': '已关闭',
}

export const TRADE_CHANNEL_DICT = {
  '1': '小程序',
  '2': '直播间',
  '3': '云闪付',
  '4': '企服',
}

export const FREEZE_STATUS_CUSTOM = {
  '0': '未解冻',
  '1': '已解冻',
}

export const BILL_TYPE_CUSTOM = {
  '1': '个人',
  '2': '公司',
}

export const USER_TYPE_CUSTOM = {
  '0': '出行人',
  '1': '购买人',
}

export const CERTIFICATE_TYPE = {
  '身份证': '身份证',
}

export const GENDER_CUSTOM = {
  '1': '男',
  '2': '女',
}

export const WEEK_LIST = ['日', '一', '二', '三', '四', '五', '六']