import Cookies from 'js-cookie';

/**
 * 记录登录
 */
export function setLogin() {
  Cookies.set('is_login', 1, { expires: 7 });
}

/**
 * 判断是否登录
 */
export function isLogin() {
  return Cookies.get('is_login') === '1';
}

/**
 * 清除登录状态
 */
export function clearLogin() {
  Cookies.remove('is_login');
  Cookies.remove('token');
}

/**
 * 存储 token
 * @param {string} token (bearer 开头)
 */
export function setToken(token) {
  console.log('AuthUtil, 存储token', token);
  if (token) {
    Cookies.set('token', token, { expires: 7 });
  }
}

/**
 * 获取 token
 */
export function getToken() {
  return Cookies.get('token') || null;
}

/**
 * 获取不带 bearer 的 token
 */
export function getTokenWithoutBearer() {
  let token = getToken();
  if (token) {
    token = token.replace('bearer ', '');
  }
  return token;
}