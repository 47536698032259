import { message } from 'antd';
import { clearLogin } from '../core/authUtil';
import { ACTION_TYPES } from '../actions';

const INIT_STATE = {
  message: '',
  type: '',
};

async function showErrorMessage(msg) {
  message.error(msg, 2);
}

function errors(state = INIT_STATE, action) {
  let result = state;
  const { type } = action;
  let { payload } = action;
  if (!payload) {
    payload = {};
  }
  const { message = '' } = payload;
  switch (type) {
    case ACTION_TYPES.ERROR_500:
      showErrorMessage('系统出现错误,请联系客服解决');
      console.error(message);
      result = {
        type,
        message,
      };
      break;
    case ACTION_TYPES.ERROR_401:
      showErrorMessage('用户未登录,请登录后再试');
      clearLogin();
      // TODO: 以后得放开
      // setTimeout(() => {
      //   window.location.assign('/login');
      // }, 1000);
      result = {
        type,
        message,
      };
      break;
    case ACTION_TYPES.ERROR_BIZ:
      console.debug('ERROR_BIZ', message);
      showErrorMessage(message);
      result = {
        type,
        message,
      };
      break;
    default:
      result = null;
  }
  return result;
}

export default errors;
