import 'moment/locale/zh-cn';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout';

// 页面路由配置
import routeConfig from './routeConfig';

const createRoutes = (routeConfigList = []) => (
  routeConfigList.map(({ path, component: Component }) => {
    return (
      <Route exact key={path} path={path} element={<Component />} />
    )
  })
)

const Index = () => {
  const excludePageList = ['/404'];
  const routeList = createRoutes(routeConfig).filter(({ key }) => !excludePageList.includes(key));
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Layout />}>
          {routeList}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Index;