import { ERRORS } from '../core/Consts';
import Global from './global';
const ACTION = wrapExceptionHandler({
  ...Global.ACTION,
});

const ACTION_TYPES = {
  ...ERRORS,
  ...Global.ACTION_TYPES,
};

function wrapExceptionHandler(actions) {
  const retActions = {};
  Object.keys(actions).forEach((key) => {
    const handler = actions[key];
    retActions[key] = async (...args) => {
      try {
        const result = await handler.apply(this, args);
        return result;
      } catch (response) {
        let type = ERRORS.ERROR_500;
        if (response.status !== 200) {
          switch (response.code) {
            case 401:
            case '-401':
            case -401:
              type = ERRORS.ERROR_401;
              break;
            default:
              type = ERRORS.ERROR_500;
          }
        } else {
          type = ERRORS.ERROR_BIZ;
          if (response.code === -401) {
            type = ERRORS.ERROR_401;
          }
        }
        return {
          type,
          payload: response
        };
      }
    };
  });

  return retActions;
}

export default ACTION;
export { ACTION_TYPES };
