import 'lib-flexible';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import store from './store';
import App from './pages';

import 'antd/dist/antd.css'
require('./style.scss');

ReactDOM.render((
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>
), document.getElementById('root'));